import { Navigate, useRoutes } from "react-router-dom";

import Main from "src/components/main/main";
import Services from "src/components/services/services";
import AboutUs from "src/components/about/about_us";
import ContactUs from "src/components/forms/contact_us";
import NewPatient from "src/components/forms/new_patient";
import RequestAppointment from "src/components/forms/request_appointment"; 
import CareCredit from "src/components/resources/carecredit";
import FAQs from "src/components/resources/faq";

/* --- Services Pages --- */
import Cats from "src/components/services/cats";
import Dogs from "src/components/services/dogs";
import Immunizations from "src/components/services/immunizations";
import Dental from "src/components/services/dental";
import Surgical from "src/components/services/surgical";
import Radiology from "src/components/services/radiology";
import Wellness from "src/components/services/wellness";
import FleaAndTick from "src/components/services/flea-tick";
import Microchipping from "src/components/services/microchipping";
import EndOfLife from "src/components/services/end-of-life";

export default function Router(props) {

    let { showSnack, googleLoaded } = props;

    const routes = useRoutes([
        { path: "/", element: <Main showSnack={showSnack} /> },
        { path: "/services", element: <Services showSnack={showSnack} /> },
        { path: "/about-us", element: <AboutUs showSnack={showSnack} /> },
        { path: "/contact-us", element: <ContactUs showSnack={showSnack} /> },
        { path: "/new-patient", element: <NewPatient showSnack={showSnack} googleLoaded={googleLoaded} /> },
        { path: "/request-appointment", element: <RequestAppointment showSnack={showSnack} /> },
        { path: "/financing/carecredit", element: <CareCredit showSnack={showSnack} /> },
        { path: "/faqs", element: <FAQs /> },
        { path: "/services/cats", element: <Cats /> },
        { path: "/services/dogs", element: <Dogs /> },
        { path: "/services/immunizations", element: <Immunizations /> },
        { path: "/services/dental", element: <Dental /> },
        { path: "/services/surgical", element: <Surgical /> },
        { path: "/services/radiology", element: <Radiology /> },
        { path: "/services/wellness", element: <Wellness /> },
        { path: "/services/flea-tick", element: <FleaAndTick /> },
        { path: "/services/microchipping", element: <Microchipping /> },
        { path: "/services/end-of-life-care", element: <EndOfLife /> },
        { path: "*", element: <Navigate to="/" replace /> }
    ]);

    return routes;
}